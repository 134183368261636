<template>
  <v-container style="min-height: 100vh;">
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <v-card class="mt-4">
      <v-card-text>

        <!--    <v-card class="elevation-0">
              <v-card-title id="v-step-0">
                لیست فاکتور ها
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>-->
        <v-expansion-panels accordion class="my-3 factors">
          <v-expansion-panel class="my-1 elevation-0">
            <v-row class="pl-10" dense>
              <v-col>
                <div class="text-center">
                  {{ $t('$vuetify.factorsPage.tableHeaders[0]') }}
                </div>
              </v-col>
              <v-col>
                <div class="text-center">
                  {{ $t('$vuetify.factorsPage.tableHeaders[1]') }}
                </div>
              </v-col>
              <v-col>
                <div class="text-center">
                  {{ $t('$vuetify.factorsPage.tableHeaders[2]') }}
                </div>
              </v-col>
              <v-col>
                <div class="text-center">
                  {{ $t('$vuetify.factorsPage.tableHeaders[3]') }}
                </div>
              </v-col>
              <v-col>
                <div class="text-center">
                  {{ $t('$vuetify.factorsPage.tableHeaders[4]') }}
                </div>
              </v-col>
              <v-col>
                <div class="text-center">
                  {{ $t('$vuetify.factorsPage.tableHeaders[5]') }}
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel>

          <v-expansion-panel class="my-1 elevation-0"
                             v-for="(item,i) in factors"
                             :key="i"
          >
            <v-expansion-panel-header>
              <v-row dense>
                <v-col class="text-center">
                  <span v-if="!$vuetify.rtl">
                    {{ langCurrency(item.currency_code) }}
                  </span>{{ (Number(item.final_amount).toLocaleString()) }}
                  <span v-if="$vuetify.rtl">
                      {{ langCurrency(item.currency_code) }}
                  </span>
                </v-col>
                <v-col class="text-center">
                  <span v-if="!$vuetify.rtl">
                    {{ langCurrency(item.currency) }}</span>{{ (Number(item.remaining_amount).toLocaleString()) }}
                  <span v-if="$vuetify.rtl">
                      {{ langCurrency(item.currency) }}
                  </span>
                </v-col>

                <v-col class="text-center">
                  <span v-if="!$vuetify.rtl">
                    {{ langCurrency(item.currency_code) }}</span>
                  {{ Number(item.discount).toLocaleString() }}
                  <span v-if="$vuetify.rtl">
                      {{ langCurrency(item.currency_code) }}
                  </span>

                </v-col>
                <v-col class="text-center">
                  {{ item.coupon_code }}
                </v-col>
                <v-col class="text-center" dir="ltr">
                  <span v-if="checkLang == 'fa'">
                       {{ item.submitted_at | toJalali }}
                  </span>
                  <span v-else>
                    {{ item.submitted_at | ToGregorian }}
                  </span>
                </v-col>
                <template v-if="item.canBePaid === true">
                  <v-col class="text-center pa-0">
                    <v-chip class="outlined-warning ma-0" small>
                      {{ $t('$vuetify.factorsPage.Payable') }}
                    </v-chip>
                  </v-col>
                </template>
                <template v-else>
                  <v-col class="text-center">
                    {{ resolveCartState(item.state) }}
                  </v-col>
                </template>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col cols="12">
                  <v-card class="elevation-2">
                    <v-card-title>
                      <h3 class="card-title pmd-title-separator pt-0">
                        {{ $t('$vuetify.factorsPage.selectedPackage') }}</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-data-table
                          hide-default-footer
                          :headers="packageHeader"
                          :items="item.packages"
                      >
                        <template v-slot:item.pivot.final_price="{item}">
                         <span v-if="!$vuetify.rtl">{{ langCurrency(item.currency) }}</span>
                          {{ Number(item.pivot.final_price).toLocaleString() }}
                          <span v-if="$vuetify.rtl">
                          {{ langCurrency(item.currency) }}
                          </span>
                        </template>
                        <template v-slot:item.pivot.price="{item}">
                          <span v-if="!$vuetify.rtl">{{ langCurrency(item.currency) }}</span>
                          {{ Number(item.pivot.price).toLocaleString() }}
                          <span v-if="$vuetify.rtl">
                          {{ langCurrency(item.currency) }}
                          </span>
                        </template>
                        <template v-slot:item.pivot.discount="{item}">
                          <span v-if="!$vuetify.rtl">{{ langCurrency(item.currency) }}</span>
                          {{ Number(item.pivot.discount).toLocaleString() }}
                          <span v-if="$vuetify.rtl">
                          {{ langCurrency(item.currency) }}
                          </span>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-card>

                    <v-card-text>
                      <v-data-table
                          hide-default-footer
                          :headers="paymentsHeaders"
                          :items="item.payments"
                      >
                        <template v-slot:item.type="{item}">
                          {{ resolvePaymentType(item.type) }}
                        </template>
                        <template v-slot:item.state="{item}">
                          {{ resolvePaymentState(item.state) }}
                        </template>

                        <template v-slot:item.final_amount="{item}">
                          <span v-if="!$vuetify.rtl">{{ langCurrency(item.currency) }}</span>
                          {{ (Number(item.final_amount).toLocaleString()) }}
                          <span v-if="$vuetify.rtl">
                          {{ langCurrency(item.currency) }}
                          </span>
                          <!--<template v-if="item.currency != 'dollar'">-->
                          <!--{{(Number(item.final_amount).toLocaleString())}}-->
                          <!--</template>-->
                          <!--<template v-else>-->
                          <!--<span class="ma-3">{{ item.final_amount}}</span>-->
                          <!--</template>-->
                        </template>
                        <template v-slot:item.date="{item}">
                          <span v-if="checkLang == 'fa'">
                              {{ item.date | toJalali }}
                          </span>
                          <span v-else>
                              {{ item.date | ToGregorian }}
                          </span>
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <template>
                        <v-btn class="blue white--text"
                               @click="$router.push({name:'clientSingleFactor',params:{factorId:item.id}})"
                        >
                          {{ $t('$vuetify.payableFactor.action') }}
                        </v-btn>
                      </template>
                      <!--<template v-else>-->
                      <!--<v-btn class="blue white&#45;&#45;text" @click="appendToOpenCart(item)">-->

                      <!--الحاق به سبد خرید-->
                      <!--</v-btn>-->
                      <!--</template>-->

                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="text-center" data-v-step="2">
          <v-pagination
              v-model="page"
              :length="total"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
    <!--      </v-card-text>
        </v-card>-->
  </v-container>
</template>
<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import momentJalali from 'moment-jalaali';
import moment from "moment-timezone";

export default {
  mounted: function () {
    // console.log('moment',moment)
    // this.$tours['myTour'].start()
  },
  data() {
    return {

      steps: [
        {
          target: '#v-step-0',  // We're using document.querySelector() under the hood
          params: {
            enableScrolling: false,
          },
          header: {
            title: 'Get Started',
          },
          content: `کانتنت 1`
        },
        {
          target: '.v-step-1',
          // params: {
          //     enableScrolling: false,
          // },
          content: 'کانتنت 2'
        },
        {
          target: '[data-v-step="2"]',
          content: 'کانتنت 3',
          params: {
            placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            // enableScrolling: false,
          }
        }
      ],


      paymentsHeaders: [
        {text: this.$t('$vuetify.factorsPage.paymentsHeaders[0]'), value: 'final_amount'},
        {text: this.$t('$vuetify.factorsPage.paymentsHeaders[1]'), value: 'type'},
        {text: this.$t('$vuetify.factorsPage.paymentsHeaders[2]'), value: 'state'},
        {text: this.$t('$vuetify.factorsPage.paymentsHeaders[3]'), value: 'date'},

      ],
      headers: [
        {
          text: 'ارز',
          align: 'center',
          sortable: false,
          value: 'currency',
        },
        {text: 'مبلغ پرداختی', value: 'price', align: 'center'},
        {text: 'تخفیف', value: 'discount', align: 'center'},
        // {text: 'تخفیف محصولات', value: 'items_discount', align: 'center'},
        {text: 'تاریخ ', value: 'submitted_at', align: 'center'},
      ],
      packageHeader: [
        {
          text: this.$t('$vuetify.factorsPage.packageHeader[0]'),
          value: this.$vuetify.rtl ? 'title' : "en_title",
          align: 'center'
        },
        {text: this.$t('$vuetify.factorsPage.packageHeader[1]'), value: 'pivot.quantity', align: 'center'},
        {text: this.$t('$vuetify.factorsPage.packageHeader[2]'), value: 'session_count', align: 'center'},
        {text: this.$t('$vuetify.factorsPage.packageHeader[3]'), value: 'pivot.price', align: 'center'},
        {text: this.$t('$vuetify.factorsPage.packageHeader[4]'), value: 'pivot.discount', align: 'center'},
        {text: this.$t('$vuetify.factorsPage.packageHeader[5]'), value: 'pivot.final_price', align: 'center'},
      ],
      page: 1,
      total: 1,
      factors: [],
      items: [
        {
          text: this.$t('$vuetify.dashboard'),
          disabled: false,
          to: `/${this.$route.params.lang}/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.ClientNavItem.factors'),
          disabled: true,
          to: `/${this.$route.params.lang}/panel/sessions`,
        }
      ]
    }
  },
  computed: {
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    }
  },
  created() {
    this.getClientFactors();
  },
  methods: {
    factorCanBePaid(factor) {
      let payables = ['submit', 'partially_paid'];
      return payables.includes(factor.state);
    },

    getClientFactors() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getClientFactors', {page: this.page}).then((data) => {

        this.total = data.data.pagination.total_pages;
        this.factors = data.data.factors;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });
    },

    resolveCartState(state) {
      let result;
      switch (state) {
        case 'submit' :
          result = this.$t('$vuetify.factorsPage.status[0]');
          break;
        case 'paid':
          result = this.$t('$vuetify.factorsPage.status[1]');
          break;
        case 'partially_paid':
          result = this.$t('$vuetify.factorsPage.status[2]');
          break;
      }
      return result;
    },

    resolvePaymentState(state) {
      let result;
      switch (state) {
        case 'awaiting' :
          result = this.$t('$vuetify.factorsPage.PaymentState[0]')
          break;
        case 'failed':
          result = this.$t('$vuetify.factorsPage.PaymentState[1]');
          break;
        case 'paid':
          result = this.$t('$vuetify.factorsPage.PaymentState[2]');
          break;
        case 'rejected':
          result = this.$t('$vuetify.factorsPage.PaymentState[3]');
          break;
        case 'pending':
          result = this.$t('$vuetify.factorsPage.PaymentState[4]');
          break;
      }
      return result;
    },
    resolvePaymentType(input) {
      if (input == 'online') {
        return this.$t('$vuetify.factorsPage.PaymentType[0]')
      } else if (input == 'wallet_debt_settlement') {
        return this.$t('$vuetify.factorsPage.PaymentType[1]')
      } else if (input == 'offline') {
        return this.$t('$vuetify.factorsPage.PaymentType[2]')
      } else if (input == 'wallet_payment') {
        return this.$t('$vuetify.factorsPage.PaymentType[3]')
      }
    },
    langCurrency(input) {

      switch (input) {
        case "dollar":
          return this.$t('$vuetify.currency.dollar')
        case "rial":
          return this.$t('$vuetify.currency.rial');
        case "euro":
          return this.$t('$vuetify.currency.Euro')
      }

      if (input == "دلار") {
        return this.checkLang == 'fa' ? "دلار" : "$"
      } else if(input=='تومان') {
        return this.checkLang == 'fa' ? "تومان" : "T "
      }
    }
  },
  watch: {

    page() {
      this.getClientFactors();
      // this.$vuetify.goTo(this.$refs.factors.offsetTop);
    }
  },
  filters: {
    toJalali(input) {
      return momentJalali(input, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
    },
    ToGregorian(input) {
      return moment(input).format('YYYY-MM-DD')
    }
  }

}
</script>

<style>
/*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):not(:first-child) {*/
/*border-radius: 15px;*/
/*border-left: 8px solid #43378C;*/
/*}*/

/*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):first-child {*/
/*border-radius: 15px;*/
/*border-left: 8px solid #86C23B;*/
/*}*/
</style>

<style scoped>
/*.green-border {*/
/*border: 5px solid #86C23B;*/
/*}*/

</style>